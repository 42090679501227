<template>
  <div class="grey lighten-4 fill-height d-flex justify-center align-start">
    <v-card max-width="800px" class="mt-10">
      <v-card-title>
        Configuração de Usuário
      </v-card-title>
      <v-card-subtitle>
        Mais alguns passos e você terá acesso à conta.
      </v-card-subtitle>
      <v-form ref="form" @submit.prevent="setPassword()">
        <v-card-text>
          <v-text-field
            v-model="user.accountName"
            label="Conta Principal"
            readonly filled
            hide-details="auto"
            class="mb-2"
            :rules="[$rules.required]"
            :disabled="loadingSave"
          />
          <v-text-field
            v-model="user.name"
            label="Nome do Usuário"
            readonly filled
            hide-details="auto"
            class="mb-2"
            :rules="[$rules.required]"
            :disabled="loadingSave"
          />
          <v-text-field
            v-model="user.email"
            label="E-Mail"
            readonly filled
            hide-details="auto"
            class="mb-2"
            :rules="[$rules.required]"
            :disabled="loadingSave"
          />
          <v-text-field
            v-model="user.password"
            label="Senha *"
            filled
            hide-details="auto"
            class="mb-2"
            :rules="[$rules.required, $rules.passwordMin]"
            :disabled="loadingSave"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :type="showPassword ? 'text' : 'password'"
          />
          <v-text-field
            label="Confirmação da Senha *"
            filled
            hide-details="auto"
            class="mb-2"
            :disabled="loadingSave"
            :rules="[
              $rules.required,
              $rules.passwordMin,
              $rules.equalPassword(() => user.password),
            ]"
            :append-icon="confirmRepeat ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="confirmRepeat = !confirmRepeat"
            :type="confirmRepeat ? 'text' : 'password'"
          />
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <div class="d-flex align-center">
            <div class="mr-4">
              <v-checkbox
                :disabled="loadingSave"
                label="Concordo com os Termos e Condições de Uso"
                hide-details="auto"
                class="my-0"
                :rules="[(val) => !!val || 'Você deve aceitar para continuar']"
              />
              <v-checkbox
                :disabled="loadingSave"
                label="Concordo com a Política de Privacidade"
                hide-details="auto"
                class="my-0"
                :rules="[(val) => !!val || 'Você deve aceitar para continuar']"
              />
            </div>
            <v-btn
              :loading="loadingSave"
              color="success"
              type="submit"
              large
            >
              Configurar Usuário
            </v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ConfigureUser",

  data: () => ({
    loading: false,
    loadingSave: false,
    confirmPassword: false,
    confirmRepeat: false,
    showPassword: false,
    value: false,
    user: {
      accountName: "",
      name: "",
      email: "",
      password: "",
    },
  }),

  computed: {
    code(){
      return this.$route.params.code;
    },
  },

  methods: {
    getData(){
      this.loadingSave = true;
      this.$axios.get(`/account-user/${this.code}`)
      .then((response) => {
        this.user = response.data.account_user;
        this.user.accountName = response.data.account_name;
        this.$refs.form.resetValidation();
      })
      .catch((error) => {
        if(error.response){
          if(error.response.status == 404){
            this.$router.replace({ name: 'login'})
            this.$showMessage("warning", "Link de configuração de usuário inválido")
            return;
          }
        }
        this.$showMessage("error", "Ocorreu um erro inesperado")
      })
      .finally(() => {
        this.loadingSave = false;
      })
        
    },
    setPassword() {
      if (!this.$refs.form.validate()){
        this.$showMessage("error", "Preencha todos os campos corretamente");
      } else {
        this.loadingSave = true;
        this.$axios
          .put(`/account-user/${this.code}`, this.user)
          .then(() => {
            this.$showMessage("success", "Usuário Configurado com sucesso");
            this.$router.replace({
              name: "login",
            });
          })
          .catch((error) => {
            this.$showMessage("error", "Falha Inesperada");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
  },

  mounted() {
    this.getData()
  },
};
</script>
